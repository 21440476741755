<template>
  <div id="app">
    <headerComp :links="allowLinks" @toggle-dialog="handleToggleDialog" :notificationsCount="notificationsCount" />
    <main style="padding: 0 20px;">
      <router-view />
    </main>
    <footerComp />
    <dialogNavComp v-if="openDialog" :links="allowLinks" />
    <CookieComponent v-if="showCookie" @close="showCookie = false" />
    <NotificationWrapper />
  </div>
</template>

<script>
import HeaderComp from './components/headerComp.vue';
import FooterComp from './components/footerComp.vue';
import dialogNavComp from './components/dialogNavComp.vue';
import NotificationWrapper from './components/NotificationWrapper.vue';
import axios from 'axios';
import { BaseUrl } from '@/api';
import CookieComponent from './components/CookieComponent.vue';

export default {
  name: "mainApp",
  components: {
    HeaderComp,
    FooterComp,
    dialogNavComp,
    NotificationWrapper,
    CookieComponent
  },
  data() {
    return {
      openDialog: false,
      token: false,
      allowLinks: [],
      links: [
        { name: 'О сервисе', href: '/' },
        { name: 'Чекер', href: '/checker' },
        { name: 'История', href: '/checker/history' },
        { name: 'Оплата', href: '/payment' },
        { name: 'Инструменты', href: '/tools' },
        { name: 'F.A.Q.', href: '/faq' },
        { name: 'Регистрация', href: '/register' },
        { name: 'Вход', href: '/login' },
        { name: 'Аккаунт', href: '/dashboard' }
      ],
      notificationsCount: 0,
      showCookie: false
    }
  },
  watch: {
    $route() {
      if (this.$store.state.meActive) {
        axios.get(`${BaseUrl}/regular_notifications_count`, { withCredentials: true }).then(res => this.notificationsCount = res.data.count);
      }
    }
  },
  async mounted() {
    if (localStorage.getItem('tg-checker-cookie-accept')) {
      this.showCookie = false;
    } else {
      this.showCookie = true;
    }

    try {
      let user = await axios.get(`${BaseUrl}/me`, {
        withCredentials: true
      })
      if (user.status == 200) {
        if (user.data.email) {
          this.token = true;
          this.$store.state.meActive = true;
          this.$store.state.userData = user.data;
          this.updateLinks();
        }
        else {
          this.token = false;
        }

        await axios.get(`${BaseUrl}/regular_notifications_count`, { withCredentials: true }).then(res => this.notificationsCount = res.data.count);
      }
      this.updateLinks();
      this.openDialog = false;
    }
    catch (e) {
      this.updateLinks();
    }
    const token = this.token;

    this.allowLinks = this.links.filter(link => {
      if (token) {
        return !(link.href === '/register' || link.href === '/login');
      } else {
        return !(link.href === '/dashboard' || link.href == '/payment' || link.href == '/checker' || link.href == '/checker/history');
      }
    });

    this.$event.on('update-header', () => {
      this.allowLinks = this.links.filter(link => {
        if (this.$store.state.meActive) {
          return !(link.href === '/register' || link.href === '/login');
        } else {
          return !(link.href === '/dashboard' || link.href == '/payment' || link.href == '/checker' || link.href == '/checker/history');
        }
      });
    })

  },
  methods: {
    handleToggleDialog() {
      this.openDialog = !this.openDialog;
    },
    updateLinks() {
      let token = this.token;
      this.allowLinks = this.links.filter(link => {
        if (token) {
          return !(link.href === '/register' || link.href === '/login');
        } else {
          return !(link.href === '/dashboard' || link.href == '/payment' || link.href == '/checker');
        }
      });
      // this.$forceUpdate(); 
    }
  }
}
</script>



<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

html,
body,
#app {
  width: 100%;
  height: 100dvh;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

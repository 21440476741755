/* eslint-disable */
<template>
  <section id="about" class="container">
    <div class="info text-base-900 flex gap-6 flex-col">
      <h1 class="text-5xl font-semibold">О сервисе</h1>
      <p>
        Чекер номеров. Проверяет, зарегистрированы ли в Telegram номера из вашей базы. Выдаёт всю информацию о
        зарегистрированных пользователях.
      </p>
      <ol class="flex flex-col gap-2 custom-list">
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
              stroke="#419FD9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          Софт видит теневые ограничения Telegram и сам регулирует процесс чекинга.
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 17L5 19L9 15M13 6H21M13 12H21M13 18H21M4 5H8C8.55228 5 9 5.44772 9 6V10C9 10.5523 8.55228 11 8 11H4C3.44772 11 3 10.5523 3 10V6C3 5.44772 3.44772 5 4 5Z"
              stroke="#419FD9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          Результат можно сохранить в любом формате и с любым набором данных. Например, в Excel файле, где будет
          записан юзернейм, пол, номер телефона, время последнего онлайна, имя, фамилия и так далее.
        </li>
        <!-- <li>Стоимость и условия использования: после начала проверки на вашем балансе временно блокируется сумма, равная
          стоимости успешной проверки + био (если включено) + определение гендера (если включено), умноженной на
          количество номеров. После проверки с баланса списывается итоговая сумма, но за те номера, которые не найдены,
          снимается только стоимость попытки проверки
          <br><br>Цены:<br>
          Успешная проверка: {{ tarrifs.success }}₽<br>
          Попытка проверки: {{ tarrifs.trying }} ₽<br>
          Парсинг био: {{ tarrifs.bio }}₽<br>
          Определение пола: {{ tarrifs.gender }}₽<br>

        </li> -->
      </ol>

      <div class="illustration">
        <!-- Вызов метода с использованием скобок -->
        <img :src="getIllustrationSrc()" alt="illustration-about">
      </div>
    </div>
    <div class="flex flex-row gap-4">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.09 10.37C19.0353 10.7224 19.8765 11.3075 20.5358 12.0712C21.195 12.8349 21.6511 13.7524 21.8617 14.7391C22.0724 15.7257 22.0309 16.7495 21.741 17.7158C21.4512 18.6822 20.9223 19.5598 20.2034 20.2676C19.4845 20.9754 18.5987 21.4905 17.628 21.7652C16.6572 22.04 15.6329 22.0655 14.6497 21.8395C13.6665 21.6134 12.7561 21.1431 12.0028 20.472C11.2495 19.8009 10.6776 18.9507 10.34 18M7 6H8V10M16.71 13.88L17.41 14.59L14.59 17.41M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
          stroke="#419FD9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div class="info__terms">
        <p>Прозрачные условия использования.</p>
        <p><span>Стоимость</span> проверки зависит от количества номеров и параметров:</p>
        <p style="white-space: nowrap;">Аккаунт не найден: <span class="line"></span> <span>{{ tarrifs.trying ? `${tarrifs.trying} ₽` : 'бесплатно' }}</span></p>
        <p style="white-space: nowrap;">Аккаунт найден: <span class="line"></span> <span>{{ `${tarrifs.success} ₽ за номер` }}</span></p>
        <span>Дополнительно:</span>

        <ol>
          <li>Парсинг описания профиля (био)</li>
          <li>Определение пола пользователя</li>
          <li>Окончательная стоимость рассичтывается автоматически, в зависимости от выбранных настроек</li>
        </ol>
        <p v-if="!tarrifs.trying">Оплата только за найденные аккаунты!</p>
        <a :href="href" class="btn-primary mt-4 px-12 py-5 rounded-xl">Начать работу</a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { BaseUrl } from '@/api';
import { mapState } from 'vuex';

export default {
  name: "landingView",
  computed: {
    ...mapState(['isDark']),
    href() {
      if (this.$store.state.meActive) {
        return '/dashboard';
      }
      return '/register';
    }
  },
  methods: {
    getIllustrationSrc() {
      return this.isDark ? require('@/assets/illustrations/about-dark.svg') : require('@/assets/illustrations/about.svg');
    }
  },
  data() {
    return {
      tarrifs: []
    }
  },
  async mounted() {

    let tariffs = await axios.get(`${BaseUrl}/tariffs`, { withCredentials: true });
    let content = tariffs.data;
    this.tarrifs = content;
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 1030px) {
    flex-direction: column;
  }
}

.info {
  flex: 1;
  max-width: 550px;
  width: 100%;
  margin-top: -20px;
  align-items: start;
}

.illustration {
  flex: 1;
  max-width: 500px;
}

img {
  width: 100%;
  height: auto;
}

.custom-list {
  list-style-type: none;

  font-size: 15px;
  font-weight: 300;
  line-height: 24px;

  li {
    margin-top: 1em;
    position: relative;
    padding-left: 30px;
    display: flex;
    gap: 16px;

    svg {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
    }
  }
}

.btn-primary {
  background-color: #419FD9;
  color: white;
  transition: background-color 0.3s;

  &:hover {
    background-color: #317aab;
  }
}

.info__terms {
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 18px;

  p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 6px;

    .line {
      width: 100%;
      height: 1px;
      border: 1px dashed #747A80;
    }
  }

  span {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
  }

  ol {
    margin-left: 20px;

    li {
      list-style-type: decimal;
    }
  }
}
</style>

<template>
    <NotificationComponent v-if="isShown" :notifiaction="currentNotification" @close="isShown = false" class="not-faded"
        :class="{ 'fade': timeLeft < 3 }" />
</template>

<script>
import NotificationComponent from './NotificationComponent.vue';
import axios from 'axios';
import { BaseUrl } from '@/api';

let interval = null;

export default {
    name: "NotificationWrapper.vue",
    components: {
        NotificationComponent
    },
    data() {
        return {
            isShown: false,
            timeLeft: 10,
            currentNotification: {},
            notifications: [],
            isActive: true,
            isActiveCurrent: true,
        }
    },
    methods: {
        setNotification() {
            if (!this.currentNotification?.created_at) {
                if (this.notifications.length > 0) {
                    this.currentNotification = this.notifications[0];
                    this.notifications.splice(0, 1);
                    this.isShown = true;

                    this.hide();
                }
            }
        },
        checkActive() {
            let interval = setTimeout(() => {
                this.isActive = false;
            }, 15000);
            let secondInterval = setTimeout(() => {
                this.isActiveCurrent = false;
            }, 5000);

            document.addEventListener('mousemove', () => {
                clearInterval(interval);
                clearInterval(secondInterval);
                this.isActive = true;
                this.isActiveCurrent = true;

                interval = setTimeout(() => {
                    this.isActive = false;
                }, 15000);

                secondInterval = setTimeout(() => {
                    this.isActiveCurrent = false;
                }, 5000);
            })
        },
        hide() {
            if (this.isActiveCurrent) {
                const interval = setInterval(() => {
                    this.timeLeft -= 1;
                }, 1000);

                setTimeout(() => {

                    clearInterval(interval);
                    this.timeLeft = 10;
                    this.isShown = false;
                    this.currentNotification = {};

                    this.setNotification();
                }, 10000);
            }
        }
    },
    watch: {
        isActive(val) {
            clearInterval(interval);

            if (this.$store.state.meActive) {
                axios.get(`${BaseUrl}/onetime_notifications`, {
                    withCredentials: true
                }).then(res => {
                    if (res.data) {
                        this.notifications = [...res.data.data, ...this.notifications];
                        this.setNotification();
                    }
                }).catch(err => {
                    console.log(err);
                    clearInterval(interval);
                });

                if (val) {
                    interval = setInterval(() => {
                        axios.get(`${BaseUrl}/onetime_notifications`, {
                            withCredentials: true
                        }).then(res => {
                            if (res.data) {
                                this.notifications = [...res.data.data, ...this.notifications];
                                this.setNotification();
                            }
                        }).catch(err => {
                            console.log(err);
                            clearInterval(interval);
                        });
                    }, 5000);
                } else {
                    interval = setInterval(() => {
                        axios.get(`${BaseUrl}/onetime_notifications`, {
                            withCredentials: true
                        }).then(res => {
                            if (res.data) {
                                this.notifications = [...res.data.data, ...this.notifications];
                                this.setNotification();
                            }
                        }).catch(err => {
                            console.log(err);
                            clearInterval(interval);
                        });
                    }, 60000);
                }
            }
        },
        isActiveCurrent(val) {
            if (val) {
                this.hide();
            }
        }
    },
    mounted() {
        this.checkActive();

        setTimeout(() => {
            if (this.$store.state.meActive) {
                interval = setInterval(() => {
                    axios.get(`${BaseUrl}/onetime_notifications`, {
                        withCredentials: true
                    }).then(res => {
                        if (res.data) {
                            this.notifications = [...res.data.data, ...this.notifications];
                            this.setNotification();
                        }
                    }).catch(err => {
                        console.log(err);
                        clearInterval(interval);
                    });
                }, 5000);
            }
        }, 1000);
    }
}
</script>

<style lang="scss" scoped>
.not-faded {
    opacity: 1;
    transition: all 2s ease-in-out;
}

.fade {
    opacity: 0;
}
</style>